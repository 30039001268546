import React from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import LocationPin from './LocationPin';

const StyledMap = styled.div`
  height: 400px;
  width: 100%;
`;

const Map = ({ location, zoomLevel }) => {
  return (
    <StyledMap>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBx3TyfCFVJdXhwfcatdK_uu2cgKiDDxHQ' }}
        defaultCenter={location}
        defaultZoom={zoomLevel}
      >
        <LocationPin
          lat={location.lat}
          lng={location.lng}
          text={location.address}
        />
      </GoogleMapReact>
    </StyledMap>
  );
};

export default Map;
