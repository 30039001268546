import * as React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookOpen,
  faComments,
  faUsers,
  faCheck,
  faPhoneAlt,
  faEnvelope,
  faClock,
  faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';

import CalculatorMobile from '../images/calculator_mobile.svg';
import Calculator from '../images/calculator.svg';
import Chart from '../images/chart.svg';
import Wallet from '../images/portfel.svg';
import Envelope from '../images/envelope.svg';

import Button from '../components/Basic/Button';
import Layout from '../components/Layout/Layout';
import Container from '../components/Basic/Container';
import Col from '../components/Basic/Col';
import Row from '../components/Basic/Row';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '../components/Basic/List';

import { AnchorLink } from "gatsby-plugin-anchor-links";
import Map from '../components/Map/Map';
import Seo from "../components/seo";

const Hero = styled.section`
  width: 100%;
  display: flex;
  margin-top: -130px;
  background: ${({ theme }) => theme.backgroundColor.primary};
  background-image: ${({ theme }) => `url(${CalculatorMobile}), linear-gradient(132deg, ${theme.gradient.first} 0%, ${theme.gradient.second} 100%)`};
  background-repeat: no-repeat;
  background-size: 140%, 100%;
  background-position: 50% 10%;
  align-items: center;
  justify-content: space-between;
  padding: 170px 0px 50px 0px;
  flex-direction: row;
  border-radius: 0 0 100px 0;

  @media ${props => props.theme.media.fablet} {
    flex-direction: column;
    padding: 170px 0px 30px 0px;
    background-image: ${({ theme }) => `url(${Calculator}), linear-gradient(132deg, ${theme.gradient.first} 0%, ${theme.gradient.second} 100%)`};
    background-size: 75%,100%;
    background-position: 100% 46%;
  }
  @media ${props => props.theme.media.desktop} {
    margin-top: -150px;
  }
`;

const HeroInner = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;

  @media ${props => props.theme.media.desktop} {
    padding: 40px;
  }
`;

const HeroPart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1{
    color: ${({ theme }) => theme.neutralColor.white};
    text-align: center;
    padding: 0px 30px;
    font-size: 2.5rem;
    font-weight: 600;
  }

  @media ${props => props.theme.media.desktop} {
    width: 30%;
    align-items: start;

    h1{
      text-align: left;
      padding: 0px 0px;
    }
  }
`;

const StyledCTA = styled.div`
  display: flex;
  padding: 20px 0px;
  justify-content: center;

  @media ${props => props.theme.media.desktop} {
    padding: 0;
  }
`;

const StyledH2 = styled.h2`
  color: ${({ secondary, theme }) => secondary ? theme.neutralColor.white : theme.accentColor.primary};
  font-size: 36px;
  margin-bottom: 30px;
  text-align: center;

  @media ${props => props.theme.media.fablet} {
    text-align: left;
  }
`;

const StyledH3 = styled.h3`
  color: ${({ secondary, theme }) => secondary ? theme.neutralColor.white : theme.accentColor.primary};
  font-size: 1.375rem;
  margin-top: 30px;
`;

const ServiceBox = styled.div`
  margin-bottom: 40px;
`;

const ServiceTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

const ServiceIcon = styled.div`
  padding: 10px;
  color: ${({ theme }) => theme.accentColor.primary};
  font-size: 1.725rem;
  background: ${({ theme }) => theme.neutralColor.white};
  border-radius: 5px;
  width: fit-content;
`;

const ContactBox = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const ContactData = styled.div`
  display: flex;
  flex-direction: column;
`;

const ServiceIconContact = styled.div`
  color: ${({ theme }) => theme.accentColor.primary};
  background: ${({ theme }) => theme.neutralColor.white};
  font-size: 26px;
  border-radius: 5px;
  height: 50px;
  width: 50px;
  margin-bottom: 10px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Section = styled.section`
  padding: 80px 0;
  background: ${({ theme, secondary }) => secondary ? `linear-gradient(132deg, ${theme.gradient.first} 0%, ${theme.gradient.second} 100%)` : 'inherit'};

  .pricing{
    text-align: center;

    @media ${props => props.theme.media.fablet} {
    text-align: left;
  }
  }
`;

const AboutUsParagraph = styled.p`
  text-align: center;

  @media ${props => props.theme.media.fablet} {
    text-align: left;
  }
`;

const AboutUsImage = styled.img`
  width: 80%;
  margin-top: -30px;
  float: right;
  display: none;

  @media ${props => props.theme.media.fablet} {
    text-align: left;
    display: block;
  }
`;

const location = {
  address: 'Grunwaldzka 207, Biuro Rachunkowe Beata Misztela Joanna Grabowska S.C.',
  lat: 53.137585554161454,
  lng: 17.95321912200167,
}

const IndexPage = (props) => (
  <Layout>
    <Seo title="BiuroBMJG - Biuro rachunkowe Beata Misztela Joanna Grabowska" />
    <Hero>
      <Container>
        <HeroInner>
          <HeroPart>
            <h1> Zaufaj nam i sprawdź ofertę </h1>
            <StyledCTA>
              <AnchorLink to="#kontakt">
                <Button color="secondary" round="5px" margin="30px 0px" name="Kontakt">
                  Kontakt
                </Button>
              </AnchorLink>
            </StyledCTA>
          </HeroPart>
        </HeroInner>
      </Container>
    </Hero>
    <Section id="o-nas">
      <Container>
        <Row>
          <Col md={12}>
            <StyledH2>O Nas</StyledH2>
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            <AboutUsParagraph>
              Biuro Rachunkowe jest małym rodzinnym biurem. Powstało w 2015
              roku jako działalność indywidualna a od 2017 przekształciło sie
              w spółkę cywilną. Posiadamy praktykę w księgowości od 2000 roku.
              W tym czasie prowadziłyśmy księgowość firm o różnym profilu
              działalności i na różnej formie opodatkowania. Działaność
              nasza jest objęta ubezpieczeniem OC.
            </AboutUsParagraph>
            <AboutUsParagraph>
              Prowadzimy: księgi handlowe, księgi przychodów i rozchodów,
              ryczałt, sprawy kadrowo-płacowe, rozliczenia ZUS, PFRON i GUS.
            </AboutUsParagraph>
          </Col>
          <Col md={5}>
            <AboutUsImage src={Chart} alt="Wykres" />
          </Col>
        </Row>
      </Container>
    </Section>
    <Section secondary id="uslugi">
      <Container>
        <Row style={{ justifyContent: 'center' }}>
          <StyledH2 secondary>Usługi</StyledH2>
        </Row>
        <Row>
          <Col md={4}>
            <ServiceBox>
              <ServiceTitle>
                <ServiceIcon>
                  <FontAwesomeIcon icon={faBookOpen} />
                </ServiceIcon>
                <StyledH3 secondary> Usługi księgowe</StyledH3>
              </ServiceTitle>
              <List>
                <ListItem secondary>
                  <ListItemIcon><FontAwesomeIcon icon={faCheck} /></ListItemIcon>
                  <ListItemText>Prowadzenie ryczałtu ewidencjonowanego</ListItemText>
                </ListItem>
                <ListItem secondary>
                  <ListItemIcon><FontAwesomeIcon icon={faCheck} /></ListItemIcon>
                  <ListItemText>Książki przychodów i rozchodów</ListItemText>
                </ListItem>
                <ListItem secondary>
                  <ListItemIcon><FontAwesomeIcon icon={faCheck} /></ListItemIcon>
                  <ListItemText>Ksiąg rachunkowych (pełna księgowość)</ListItemText>
                </ListItem>
                <ListItem secondary>
                  <ListItemIcon><FontAwesomeIcon icon={faCheck} /></ListItemIcon>
                  <ListItemText>Ewidencji na potrzeby podatku VAT</ListItemText>
                </ListItem>
                <ListItem secondary>
                  <ListItemIcon><FontAwesomeIcon icon={faCheck} /></ListItemIcon>
                  <ListItemText>Ewidencji środków trwałych</ListItemText>
                </ListItem>
              </List>
            </ServiceBox>
          </Col>
          <Col md={4}>
            <ServiceBox>
              <ServiceTitle>
                <ServiceIcon>
                  <FontAwesomeIcon icon={faComments} />
                </ServiceIcon>
                <StyledH3 secondary> Usługi doradztwa</StyledH3>
              </ServiceTitle>
              <p style={{ color: '#fff' }}>
                Zapewniamy wszystkim naszym klientom profesjonalne doradztwo podatkowe w celu zapewnienia prawidłowości wszystkich zobowiązań Twojej firmy
              </p>
            </ServiceBox>
          </Col>
          <Col md={4}>
            <ServiceBox>
              <ServiceTitle>
                <ServiceIcon>
                  <FontAwesomeIcon icon={faUsers} />
                </ServiceIcon>
                <StyledH3 secondary> Usługi kadrowo-płacowe</StyledH3>
              </ServiceTitle>
              <List>
                <ListItem secondary>
                  <ListItemIcon><FontAwesomeIcon icon={faCheck} /></ListItemIcon>
                  <ListItemText>Sporządzanie list płac</ListItemText>
                </ListItem>
                <ListItem secondary>
                  <ListItemIcon><FontAwesomeIcon icon={faCheck} /></ListItemIcon>
                  <ListItemText>Obliczanie podatku PIT</ListItemText>
                </ListItem>
                <ListItem secondary>
                  <ListItemIcon><FontAwesomeIcon icon={faCheck} /></ListItemIcon>
                  <ListItemText>Prowadzenie ZUS (deklaracje, rozliczenia)</ListItemText>
                </ListItem>
                <ListItem secondary>
                  <ListItemIcon><FontAwesomeIcon icon={faCheck} /></ListItemIcon>
                  <ListItemText>PFRON</ListItemText>
                </ListItem>
                <ListItem secondary>
                  <ListItemIcon><FontAwesomeIcon icon={faCheck} /></ListItemIcon>
                  <ListItemText>Teczki osobowe</ListItemText>
                </ListItem>
              </List>
            </ServiceBox>
          </Col>
        </Row>
      </Container>
    </Section>
    <Section id="cennik">
      <Container>
        <Row>
          <Col md={5}>
            <AboutUsImage style={{ float: 'left' }} src={Wallet} alt="Portfel" />
          </Col>
          <Col md={7}>
            <Row>
              <Col md={12}>
                <StyledH2>Cennik</StyledH2>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="pricing">
                <AboutUsParagraph>
                  Cena za usługi wykonywane przez nasze biuro uzgadniana jest indywidualnie w zależności od rodzaju prowadzonych ksiąg, liczby dokumentów i ilości zatrudnionych pracowników.
                </AboutUsParagraph>
                <AnchorLink to="#kontakt">
                  <Button margin="20px 0px" name="Skontaktuj się">
                    Skontatkuj się
                  </Button>
                </AnchorLink>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
    <Section secondary id="kontakt">
      <Container>
        <Row style={{ justifyContent: 'center' }}>
          <Col md={12}>
            <StyledH2 secondary>Kontakt</StyledH2>
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            <ContactBox>
              <ServiceIconContact>
                <FontAwesomeIcon icon={faPhoneAlt} />
              </ServiceIconContact>
              <ContactData>
                <p style={{ fontSize: '18px', fontWeight: '500', color: '#fff' }}>Telefon</p>
                <p style={{ color: '#fff' }}>511 752 540</p>
              </ContactData>
            </ContactBox>
            <ContactBox>
              <ServiceIconContact>
                <FontAwesomeIcon icon={faEnvelope} />
              </ServiceIconContact>
              <ContactData>
                <p style={{ fontSize: '18px', fontWeight: '500', color: '#fff' }}>Email</p>
                <p style={{ color: '#fff' }}>biuroj.grab@interia.pl</p>
              </ContactData>
            </ContactBox>
            <ContactBox>
              <ServiceIconContact>
                <FontAwesomeIcon icon={faMapMarkerAlt} />
              </ServiceIconContact>
              <ContactData>
                <p style={{ fontSize: '18px', fontWeight: '500', color: '#fff' }}>Adres</p>
                <p style={{ color: '#fff' }}>ul. Grunwaldzka 207, 85-451 Bydgoszcz</p>
              </ContactData>
            </ContactBox>
            <ContactBox>
              <ServiceIconContact>
                <FontAwesomeIcon icon={faClock} />
              </ServiceIconContact>
              <ContactData>
                <p style={{ fontSize: '18px', fontWeight: '500', color: '#fff' }}>Godziny Otwarcia</p>
                <p style={{ color: '#fff' }}>Pn - Pt 8.00 do 16.00</p>
              </ContactData>
            </ContactBox>
          </Col>
          <Col md={5}>
            <AboutUsImage src={Envelope} alt="Koperta" />
          </Col>
        </Row>
      </Container>
    </Section>
    <Container fluid>
      <Row>
        <Map location={location} zoomLevel={13} />
      </Row>
    </Container>
  </Layout>
);

export default IndexPage;
