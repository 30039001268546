import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const StyledLocationPin = styled.div`

  p{
    font-size: 12px;
    line-height: 120%;
    font-weight: 500;
    width: 150px;
  }

`;

const LocationPin = ({ text }) => {
  return (
    <StyledLocationPin>
      <FontAwesomeIcon icon={faMapMarkerAlt} size={'2x'} style={{ color: '#0088BC' }} />
      <p>{text}</p>
    </StyledLocationPin>
  );
};

export default LocationPin;
