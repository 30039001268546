import styled from 'styled-components';

export const List = styled.ul`
  padding: 0.5rem 0;
`;

export const ListItem = styled.li`
  width: 100%;
  display: flex;
  position: relative;
  text-align: left;
  align-items: center;
  padding: 0.5rem 0;
  justify-content: flex-start;
  text-decoration: none;
  color: ${({ secondary, theme }) => secondary ? theme.neutralColor.white : theme.neutralColor.textPrimary};
`;

export const ListItemIcon = styled.div`
  margin-right: 20px;
  flex-shrink: 0;
  background: ${({ theme }) => theme.neutralColor.white};
  border-radius: 5px;
  font-size: 16px;
  padding: 5px 5px 3px 5px;
  color: ${({ mode, theme }) =>
    mode === 'secondary' ? theme.accentColor.primary : theme.accentColor.primary};
`;

export const ListItemText = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
`;
